define("discourse/plugins/discourse-banner-popup/discourse/controllers/banner", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    banners: [],
    bannerClass: ""
  });

  _exports.default = _default;
});
define("discourse/plugins/discourse-banner-popup/discourse/initializers/banner-popup", ["exports", "discourse-common/lib/helpers", "discourse/lib/show-modal"], function (_exports, _helpers, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: "banner-popup",
    after: 'inject-objects',
    initialize: function initialize(container) {
      var BANNER_POPUP_MOBILE = "banner_popup_mobile";
      var BANNER_POPUP_DESKTOP = "banner_popup_desktop";

      if ((0, _helpers.helperContext)().siteSettings.banner_popup_enabled) {
        var position = 5;
        var delay = 0;
        var banners = [];
        var bannerClass = "";
        var uuidBannerPopup = ""; // Find or Gen UUID

        try {
          if (localStorage) {
            if (localStorage.uuidBannerPopup) {
              uuidBannerPopup = localStorage.uuidBannerPopup;
            } else {
              uuidBannerPopup = uuidv4();
              localStorage.uuidBannerPopup = uuidBannerPopup;
            }
          }
        } catch (err) {// localStorage may be disabled, just skip this
          // you get security errors if it is disabled
        } // Get position by mobile or desktop view


        if ((0, _helpers.helperContext)().site.mobileView) {
          position = 13;
          bannerClass = BANNER_POPUP_MOBILE;
        } else {
          position = 12;
          bannerClass = BANNER_POPUP_DESKTOP;
        } // Get data banner from API


        var bannerService = container.lookup("service:banner-popup");
        bannerService.getBannersByPosition(position, uuidBannerPopup).then(function (data) {
          if (data === null || data === void 0 ? void 0 : data.delay) {
            delay = data === null || data === void 0 ? void 0 : data.delay;
          }

          banners = data === null || data === void 0 ? void 0 : data.banners; // Delay show banner

          if (banners && banners.length > 0) {
            if (delay && delay > 0) {
              setTimeout(function () {
                showModalBanner(banners, bannerClass);
                markSeenBannerPopup(bannerService, banners, position, uuidBannerPopup);
              }, delay * 1000);
            } else {
              showModalBanner(banners, bannerClass);
              markSeenBannerPopup(bannerService, banners, position, uuidBannerPopup);
            }
          }
        });
      }
    }
  };
  _exports.default = _default;

  function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
      return (c ^ window.crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
    });
  }

  ;

  function showModalBanner(banners, bannerClass) {
    var modal = (0, _showModal.default)("banner");
    modal.setProperties({
      banners: banners,
      bannerClass: bannerClass
    });
  }

  ;

  function markSeenBannerPopup(bannerService, banners, position, uuidBannerPopup) {
    for (var i = 0; i < banners.length; i++) {
      var _banners$i;

      var bannerId = (_banners$i = banners[i]) === null || _banners$i === void 0 ? void 0 : _banners$i.id;
      bannerService.markSeenBannerPopup(position, bannerId, uuidBannerPopup);
    }

    return;
  }
});
define("discourse/plugins/discourse-banner-popup/discourse/services/banner-popup", ["exports", "@ember/service", "discourse-common/utils/decorators", "discourse/lib/ajax"], function (_exports, _service, _decorators, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    getBannersByPosition: function getBannersByPosition(position) {
      var uuid = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
      var settings = {
        "url": "/api/banners/position/".concat(position, "?uniq_user_id=").concat(uuid),
        "method": "GET"
      };
      return (0, _ajax.ajax)(settings).then(function (res) {
        return res;
      });
    },
    markSeenBannerPopup: function markSeenBannerPopup(position, bannerId) {
      var uuid = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
      var body = {
        uniq_user_id: uuid
      };
      var settings = {
        "url": "/api/banners/position/".concat(position, "/banner/").concat(bannerId, "/mark_read"),
        "method": "PUT",
        "headers": {
          "Content-Type": "application/json"
        },
        "data": JSON.stringify(body)
      };
      return (0, _ajax.ajax)(settings).then(function (res) {
        return res;
      });
    }
  });

  _exports.default = _default;
});
Ember.TEMPLATES["javascripts/modal/banner"] = Ember.HTMLBars.template({"id":null,"block":"{\"symbols\":[],\"statements\":[[4,\"d-modal-body\",null,[[\"autoFocus\"],[\"false\"]],{\"statements\":[[1,[28,\"mount-widget\",null,[[\"widget\",\"args\"],[\"banner-popup\",[28,\"hash\",null,[[\"banners\",\"bannerClass\"],[[24,[\"banners\"]],[24,[\"bannerClass\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}","meta":{"moduleName":"javascripts/discourse/templates/modal/banner"}});
define("discourse/plugins/discourse-banner-popup/discourse/widgets/banner-popup", ["discourse/widgets/widget", "virtual-dom", "discourse-common/lib/get-owner", "discourse/widgets/raw-html"], function (_widget, _virtualDom, _getOwner, _rawHtml) {
  "use strict";

  (0, _widget.createWidget)('banner-popup', {
    tagName: 'div.banner',
    buildKey: function buildKey() {
      return 'banner-popup-widget';
    },
    defaultState: function defaultState() {
      return {
        isSingle: true,
        image: "",
        link: "",
        id: "",
        isLoading: true,
        banners: null,
        viewed: false
      };
    },
    getHref: function getHref(contents) {
      try {
        var snippet = document.createElement("div");
        snippet.innerHTML = contents;
        var links = snippet.getElementsByTagName("a");
        return links[links.length - 1].href;
      } catch (error) {
        return "";
      }
    },
    html: function html(attrs, state) {
      var banners = attrs.banners,
          bannerClass = attrs.bannerClass;

      if (!banners || banners.length == 0) {
        return (0, _virtualDom.h)("div");
      }

      if (banners.length > 1) {
        var slides = [];
        slides = slides.concat(banners.map(function (banner) {
          return (0, _virtualDom.h)("div", {
            className: bannerClass
          }, new _rawHtml.default({
            html: "".concat(banner.script)
          }));
        }));

        if (!this.state.viewed) {
          this.appEvents.trigger("banner-popup:view", this.state);
          this.state.viewed = true;
        }

        return (0, _virtualDom.h)("div", {
          className: "owl-carousel carousel_".concat(bannerClass)
        }, slides);
      } else if (banners.length == 1) {
        this.state.link = this.getHref(banners[0].script);

        if (!this.state.viewed) {
          this.appEvents.trigger("banner-popup:view", this.state);
          this.state.viewed = true;
        }

        return (0, _virtualDom.h)("div", {
          className: bannerClass
        }, new _rawHtml.default({
          html: "".concat(banners[0].script)
        }));
      } else {
        return null;
      }
    },
    click: function click() {
      this.appEvents.trigger("banner-popup:click", this.state);
    }
  });
});

